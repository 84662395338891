import React, {useRef, useState, useEffect} from 'react';
import {useField} from '@unform/core';

import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import UISwitch from '@material-ui/core/Switch';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing(1),
  },
  helper: {
    marginLeft: theme.spacing(1),
  },
}));

const Switch = ({
  name,
  label,
  options,
  onChangeValue = () => null,
  ...rest
}) => {
  const classes = useStyles();
  const selectRef = useRef(null);

  const {fieldName, defaultValue, registerField, error} = useField(name);

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',

      getValue: () => checked,
      setValue: (_ref, value) => setChecked(!!value),
      clearValue: () => setChecked(false),
    });
  }, [fieldName, registerField, checked]);

  const handleChange = ({target}) => {
    const {checked: ckd} = target;
    onChangeValue(ckd);
    setChecked(ckd);
  };

  useEffect(() => {
    if (defaultValue) setChecked(!!defaultValue);
  }, [defaultValue]);

  return (
    <div className={classes.root}>
      <FormControl {...rest}>
        <FormControlLabel
          control={
            <UISwitch
              checked={checked}
              onChange={handleChange}
              name={fieldName}
            />
          }
          label={label}
        />

        <FormHelperText className={classes.helper}>
          {error || ' '}
        </FormHelperText>
      </FormControl>
    </div>
  );
};

export default Switch;

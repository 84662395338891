import React, {useRef, useState, useEffect} from 'react';
import {useField} from '@unform/core';

import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import UISelect from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles(theme => ({
  root: {
    // marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const Select = ({
  name,
  label,
  options,
  fullWidth = false,
  onChangeValue = () => null,
  ...rest
}) => {
  const classes = useStyles();
  const selectRef = useRef(null);

  const {fieldName, defaultValue, registerField, error} = useField(name);

  const [selected, setSelected] = useState('');

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',

      getValue: () => selected,
      setValue: (_ref, value) => setSelected(`${value}`),
      clearValue: () => setSelected(''),
    });
  }, [fieldName, registerField, selected]);

  const handleChange = ({target}) => {
    const {value} = target;
    onChangeValue(value);
    setSelected(value);
  };

  useEffect(() => {
    if (defaultValue) {
      setSelected(`${defaultValue}`);
    }
  }, [defaultValue]);

  return (
    <div className={classes.root}>
      <FormControl {...rest}>
        <InputLabel htmlFor="outlined-age-native-simple">{label}</InputLabel>

        <UISelect
          native
          ref={selectRef}
          value={selected}
          onChange={handleChange}
          label={label}
          error={!!error}
          fullWidth={fullWidth}
        >
          <option aria-label="None" value="" />
          {options.map(opt => (
            <option key={`${opt.value}:${opt.label}`} value={`${opt.value}`}>
              {opt.label}
            </option>
          ))}
        </UISelect>

        <FormHelperText>{error || ' '}</FormHelperText>
      </FormControl>
    </div>
  );
};

export default Select;

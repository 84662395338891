/* eslint-disable no-param-reassign */
import React, {useEffect, useRef} from 'react';
import {useField} from '@unform/core';

export default function Checkbox({name, label, ...rest}) {
  const inputRef = useRef(null);

  const {fieldName, defaultValue, registerField, error} = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,

      getValue: ref => !!ref.checked,

      clearValue: ref => {
        ref.checked = false;
      },

      setValue: (ref, value) => {
        ref.checked = !!value;
      },
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <div className="form-input-container">
      {/* <div className="form-checkbox-container">
        <input
          type="checkbox"
          ref={inputRef}
          defaultValue={defaultValue}
          className="checkbox"
          {...rest}
        />
        {label && <span>{label}</span>}
      </div> */}

      <label htmlFor={name} className="form-checkbox-container">
        <input
          defaultChecked={defaultValue}
          ref={inputRef}
          type="checkbox"
          id={name}
          {...rest}
        />
        {label}
      </label>

      <span className="form-error">{error || ''}</span>
    </div>
  );
}

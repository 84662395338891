import React, {useContext, useCallback, useEffect, useState} from 'react';
import {format} from 'date-fns';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import DescriptionIcon from '@material-ui/icons/Description';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Header from '../../components/Header';
import Loading from '../../components/Loading';

Numeral.locale('pt-br');

const columns = [
  {
    id: 'createdAt',
    label: 'Data',
    minWidth: 80,
    width: 150,
    format: 'date',
  },
  {id: 'title', label: 'Título', minWidth: 150},
  {id: 'subtitle', label: 'Subtítulo', minWidth: 150},
  {
    id: 'favorite',
    label: '',
    width: 40,
    format: 'bool',
    align: 'right',
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: 10,
    paddingBottom: 50,
  },
  filter: {
    marginBottom: 20,
  },
  textInputMulti: {
    // minHeight: 300,
  },
});

const ClientPosts = ({history}) => {
  const showAlert = useContext(AlertContext);
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState([]);
  const [resultInfo, setResultInfo] = useState({
    filter: '',
    page: 1,
    pages: 1,
    total: 0,
    limit: 10,
    orderBy: 'date',
    sortBy: 'DESC',
  });

  const loadData = useCallback(
    async (filter = '', page = 1, limit = 25) => {
      setIsLoading(true);

      await api
        .get(`/core/articles?p=${page}&l=${limit}&f=${filter}`)
        .then(async ({data}) => {
          if (data) {
            const {articles, ...info} = data;
            setResult(articles);
            setResultInfo(info);
            setIsLoading(false);
          }
        })
        .catch(e => {
          showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
          setIsLoading(false);
        });
    },
    [showAlert],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleArticle = id => {
    history.push(`/dashboard/artigo/${id}`);
  };

  const handleChangePage = (event, newPage) => {
    loadData(
      resultInfo.filter,
      newPage + 1,
      resultInfo.limit,
      resultInfo.orderBy,
      resultInfo.sortBy,
    );
  };

  const handleChangeRowsPerPage = ({target}) => {
    loadData(
      resultInfo.filter,
      1,
      parseInt(target.value),
      resultInfo.orderBy,
      resultInfo.sortBy,
    );
  };
  return (
    <>
      <Header
        title="Artigos"
        btnTxt="Novo Artigo"
        btnIcon={<DescriptionIcon />}
        onBtn={() => history.push('/dashboard/artigo/novo')}
      />

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      ...(column.width ? {width: column.width} : {}),
                      ...(column.minWidth ? {minWidth: column.minWidth} : {}),
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Loading />
                  </TableCell>
                </TableRow>
              ) : (
                result.map(row => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      onClick={() => handleArticle(row.id)}
                    >
                      {columns.map(column => {
                        const {id, align, format: valFormat} = column;

                        const value =
                          column.id === 'name'
                            ? row.userInformations[id]
                            : row[id];

                        return (
                          <TableCell key={`${id}.${valFormat}`} align={align}>
                            {valFormat === 'number' ? (
                              Numeral(value).format('0,0')
                            ) : valFormat === 'price' ? (
                              Numeral(value).format('$0,0')
                            ) : valFormat === 'date' ? (
                              format(new Date(value), 'dd/MM/yyyy HH:mm')
                            ) : valFormat === 'bool' ? (
                              <>{value ? <StarIcon /> : <StarBorderIcon />}</>
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={resultInfo.total}
          rowsPerPage={resultInfo.limit}
          page={resultInfo.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Exibir:"
        />
      </Paper>
    </>
  );
};

export default ClientPosts;

import React from 'react';
import {Switch} from 'react-router-dom';

import PrivateRoute from '../../components/PrivateRoute';

import Home from './pages/Home';

import Managers from './pages/Managers';
import ManagerAdd from './pages/ManagerAdd';
import Manager from './pages/Manager';

import Clients from './pages/Clients';
import ClientAdd from './pages/ClientAdd';
import Client from './pages/Client';
import ClientPosts from './pages/ClientPosts';

import Articles from './pages/Articles';
import ArticleNew from './pages/ArticleNew';
import Article from './pages/Article';

const Empty = () => <></>;

const components = {
  Empty,
  Home,
  Managers,
  ManagerAdd,
  Manager,
  Clients,
  ClientAdd,
  Client,
  ClientPosts,
  Articles,
  ArticleNew,
  Article,
};

export const userRoutes = [
  {path: '/dashboard', componentName: 'Home'},
  {path: '/dashboard/perfil', componentName: 'Empty'},
  {path: '/dashboard/senha', componentName: 'Empty'},
];

export const admRoutes = [
  {path: '/dashboard/gerentes', componentName: 'Managers'},
  {path: '/dashboard/gerente/incluir', componentName: 'ManagerAdd'},
  {path: '/dashboard/gerente/:userId', componentName: 'Manager'},

  {path: '/dashboard/clientes', componentName: 'Clients'},
  {path: '/dashboard/cliente/incluir', componentName: 'ClientAdd'},
  {path: '/dashboard/cliente/:userId', componentName: 'Client'},

  {
    path: '/dashboard/cliente/:userId/postagens',
    componentName: 'ClientPosts',
  },

  {path: '/dashboard/artigos', componentName: 'Articles'},
  {path: '/dashboard/artigo/novo', componentName: 'ArticleNew'},
  {path: '/dashboard/artigo/:articleId', componentName: 'Article'},
];

const Routes = ({classes}) => {
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <Switch>
        {userRoutes.map(dr => (
          <PrivateRoute
            key={`${dr.componentName}:${dr.path}`}
            path={dr.path}
            status={[2, 3, 4]}
            exact
            component={components[dr.componentName]}
          />
        ))}

        {admRoutes.map(dr => (
          <PrivateRoute
            key={`${dr.componentName}:${dr.path}`}
            path={dr.path}
            status={[2, 3]}
            exact
            component={components[dr.componentName]}
          />
        ))}
      </Switch>
    </main>
  );
};

export default Routes;

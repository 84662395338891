import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
  // console.log("theme :>> ", theme);

  return {
    root: {
      width: '100%',
      padding: theme.spacing(2),
    },

    filePicker: {
      marginBottom: 20,
    },

    textInput: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
    },

    contacts: {
      borderWidth: 1,
      borderColor: '#ccc',
      borderStyle: 'solid',
      borderRadius: 5,
      padding: 8,
      paddingBottom: 12,
      marginBottom: 30,
      '& > span': {
        // backgroundColor: "blue",
      },
    },

    contactsHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 5,
      '& span': {
        fontSize: 18,
        padding: 4,
        fontWeight: 'bold',
      },
    },

    contactAddBtn: {
      marginTop: -8,
      marginRight: -8,
    },

    contactItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 8,
      '& > span': {
        fontSize: 16,
      },
    },

    contactFav: {
      marginRight: 5,
      marginBottom: 2,
    },

    contactEditIcon: {
      marginLeft: 5,
    },

    contactEmpty: {
      fontStyle: 'italic',
      fontSize: 12,
    },
  };
});

export default useStyles;

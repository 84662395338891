import React from 'react';

import ufData from '../../../../../constants/ibge/uf.json';
import City from './City';

import './styles.css';

const AnyCity = ({uf, city}) => {
  const estado = ufData?.[uf];

  return estado ? (
    <div className="any-city">
      <h2 className="any-city-txt">
        Atendimento em <City {...{uf, city, estado}} />
      </h2>
    </div>
  ) : (
    <div className="any-city">
      <h2 className="any-city-txt">Atendimento em todo o Brasil</h2>
    </div>
  );
};

export default AnyCity;

import React, {useContext, useRef} from 'react';
import {Element} from 'react-scroll';
import {Form} from '@unform/web';
import * as Yup from 'yup';

import {AlertContext} from '../../../../../App';

import api from '../../../../../services/api';
import whichError from '../../../../../services/whichError';

import Input from '../../../../../components/Input';

import contatoEmail from '../../../../../assets/icon-contatos-email.png';
import contatoWhatsapp from '../../../../../assets/icon-contatos-whatsapp.png';
import contatoInsta from '../../../../../assets/icon-contatos-instagram.png';
import contatoLinkendin from '../../../../../assets/icon-contatos-linkedin.png';

import './styles.css';

const Contato = () => {
  const showAlert = useContext(AlertContext);
  const formRef = useRef(null);

  const handleSubmit = async (data, {reset}) => {
    try {
      const schema = Yup.object().shape({
        name: Yup.string()
          .transform((cv, ov) => (ov === '' ? null : cv))
          .nullable(true)
          .trim()
          .min(1, 'O nome deve ter no mínimo 1 caractere.')
          .required('Favor informar o seu nome'),
        email: Yup.string()
          .transform((cv, ov) => (ov === '' ? null : cv))
          .nullable(true)
          .trim()
          .email('Favor informar um email válido.')
          .required('Favor informar o seu e-mail'),
        phone: Yup.string()
          .transform((cv, ov) => (ov === '' ? null : cv))
          .nullable(true)
          .trim(),
        msg: Yup.string()
          .transform((cv, ov) => (ov === '' ? null : cv))
          .nullable(true)
          .trim()
          .min(10, 'A mensagem deve ter no mínimo 10 caracteres.')
          .required('Favor informar a mensagem'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const formData = schema.cast(data);

      const send = await api
        .post(`/message`, formData)
        // eslint-disable-next-line no-alert
        .catch(err => alert(whichError(err).errorMsg));

      showAlert({title: `A mensagem ${!send ? 'não ' : ''} foi enviada!`});

      formRef.current.setErrors({});
      reset();
    } catch (err) {
      // console.log('error: ', err);
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
    }

    return false;
  };

  return (
    <Element name="contato" className="contato" id="contato">
      <div className="contato-content">
        <div className="contato-item-container">
          <h1 className="section-title">Contato</h1>

          <div className="contato-item">
            <a
              href="https://wa.me/5538991285348"
              target="_blank"
              rel="noreferrer"
            >
              <img src={contatoWhatsapp} alt="Contato" />
            </a>

            <a
              href="https://wa.me/5538991285348"
              target="_blank"
              rel="noreferrer"
            >
              +55 (38) 99128-5348
            </a>
          </div>

          <div className="contato-item">
            <a
              href="https://www.instagram.com/soucriminalistaa"
              target="_blank"
              rel="noreferrer"
            >
              <img src={contatoInsta} alt="Contato" />
            </a>

            <a
              href="https://www.instagram.com/soucriminalistaa"
              target="_blank"
              rel="noreferrer"
            >
              @soucriminalistaa
            </a>
          </div>

          <div className="contato-item">
            <a href="https://www.linkedin.com/in/paulomagalhaesadv">
              <img src={contatoLinkendin} alt="Contato" />
            </a>
            <a href="https://www.linkedin.com/in/paulomagalhaesadv">
              @paulomagalhaesadv
            </a>
          </div>

          <div className="contato-item">
            <a href="mailto:criminal@paulomagalhaesadv.com">
              <img src={contatoEmail} alt="Contato" />
            </a>

            <a href="mailto:criminal@paulomagalhaesadv.com">
              criminal@paulomagalhaesadv.com
            </a>
          </div>
        </div>

        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          className="contato-form-container"
        >
          <Input name="name" placeholder="Nome" />

          <Input name="email" placeholder="E-mail" />

          <Input name="phone" placeholder="Telefone" />

          <Input name="msg" multiline placeholder="Mensagem" className="ta" />

          <button type="submit">Enviar</button>
        </Form>
      </div>
    </Element>
  );
};

export default Contato;

import React, {
  useRef,
  useContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {Form} from '@unform/web';
import * as Yup from 'yup';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Header from '../../components/Header';
import FilePicker from '../../../../components/FilePicker';

import DatePicker from '../../../../components/DatePicker';
import Input from '../../../../components/Form/Input';
import ClientPost from '../../../../components/ClientPost';
import LoadMore from '../../../../components/LoadMore';

Numeral.locale('pt-br');

const slo = {filter: '', page: 1, limit: 10, orderBy: 'date', sortBy: 'ASC'};

const useStyles = makeStyles({
  root: {width: '100%', padding: 10},
  postsContainer: {paddingBottom: 40},
  button: {marginRight: 10},
  filter: {marginBottom: 20},
});

const ClientPosts = ({match}) => {
  const formRef = useRef(null);
  const formRef2 = useRef(null);

  const {params} = match;
  const {userId} = params;

  const showAlert = useContext(AlertContext);
  const classes = useStyles();

  const [clientName, setClientName] = useState('');
  const [fixed, setFixed] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState([]);
  const [resultInfo, setResultInfo] = useState({...slo, pages: 1, total: 0});

  const [file, setFile] = useState(false);

  const [postDate, setPostDate] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  const loadData = useCallback(
    async loadOptions => {
      const options = {...slo, ...loadOptions};
      const {filter, page, limit, orderBy, sortBy, loadMore} = options;

      if (!loadMore) setIsLoading(true);

      const getFixed = loadMore
        ? null
        : api.get(`/core/client/${userId}/fixed`);

      const getPosts = api.get(
        `/core/client/${userId}/posts?&p=${page}&l=${limit}&o=${orderBy}&s=${sortBy}&f=${filter}`,
      );

      const error = [];
      const get = await Promise.all(
        [getFixed, getPosts].map((p, i) =>
          !p
            ? {data: null}
            : p.catch(e => {
                error[i] = whichError(e).errorMsg;
              }),
        ),
      );

      const errors = [...new Set(error)];
      if (errors.length > 1) {
        showAlert(
          'Atenção',
          `Ocorreram os seguintes erros:\n\n${errors.join('\n')}`,
        );
      } else if (errors.length >= 1) {
        showAlert('Erro', errors[0]);
      }

      if (errors.length > 0) return;

      if (get[0].data) {
        setClientName(get[0].data.name || '');
        setFixed(get[0].data.fixed || '');
      }

      if (get[1].data) {
        const {posts, ...info} = get[1].data;
        setResult(p => (loadMore ? [...p, ...posts] : posts));
        setResultInfo(info);
        setIsLoading(false);
      }
    },
    [showAlert, userId],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: !(resultInfo.page === resultInfo.pages),
    onLoadMore: () => loadData({page: resultInfo.page + 1, loadMore: true}),
    // disabled: !!i,
    rootMargin: '0px 0px 10px 0px',
  });

  const handleSubmit = async (unformData, {reset}) => {
    formRef.current.setErrors({});

    const schema = Yup.object().shape({
      title: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .min(1, 'O nome deve ter no mínimo 1 caractere.')
        .required('Favor informar o título da postagem'),
      msg: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .min(1, 'O nome deve ter no mínimo 1 caractere.')
        .required('Favor informar a mensagem da postagem'),
    });

    try {
      await schema.validate(unformData, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }

      return;
    }

    setIsSaving(true);

    const data = schema.cast(unformData);
    data.date = postDate || null;

    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.append('formData', JSON.stringify(data));

    if (file) formData.append('file', file);

    const save = await api
      .post(`/core/client/${userId}/posts`, formData)
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    setIsSaving(false);

    if (save) {
      showAlert({title: 'Postagem adicionada com sucesso!'});
      setResult(p => [save.data, ...p]);
      reset();
    }
  };

  const handleSubmitFixed = async unformData => {
    formRef.current.setErrors({});

    const schema = Yup.object().shape({
      fixed: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .min(1, 'O nome deve ter no mínimo 1 caractere.')
        .max(255, 'O nome deve ter no máximo 255 caractere.')
        .required('Favor informar a mensagem da postagem'),
    });

    try {
      await schema.validate(unformData, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }

      return;
    }

    setIsSaving(true);

    const data = schema.cast(unformData);

    const saveFixed = await api
      .put(`/core/client/${userId}/fixed`, data)
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    setIsSaving(false);

    if (saveFixed) setFixed(data.fixed);
  };

  const handleDeleteFixed = () =>
    api
      .delete(`/core/client/${userId}/fixed`)
      .then(() => formRef2.current.reset())
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

  const handleAskDeleteFixed = () => {
    showAlert({
      title: 'Confirmação',
      msg: 'Deseja realmente apagar esta postagem fixa?',
      onOk: handleDeleteFixed,
    });
  };

  const handleOnRemove = postId => {
    setResult(prev => prev.filter(p => p.id !== postId));
  };

  const handleFile = image => setFile(image);

  return (
    <>
      <Header title="Postagens do Cliente" />
      {isLoading ? (
        <></>
      ) : (
        <Paper className={classes.root}>
          <h2>{clientName}</h2>

          <br />

          <Form
            ref={formRef2}
            onSubmit={handleSubmitFixed}
            initialData={{fixed}}
          >
            <Input
              label="Mensagem Fixa"
              name="fixed"
              className={classes.textInput}
              variant="outlined"
              fullWidth
            />

            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              className={classes.button}
              startIcon={<AddIcon />}
              disabled={isSaving}
            >
              Salvar
            </Button>

            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={handleAskDeleteFixed}
              size="large"
              className={classes.button}
              startIcon={<DeleteForeverIcon />}
            >
              Excluir
            </Button>
          </Form>
          <br />

          <hr />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <DatePicker
              label="Início"
              onDateChange={date => setPostDate(date)}
              className={classes.filter}
              id="op-report-filter-start"
            />

            <Input
              label="Título *"
              name="title"
              className={classes.textInput}
              variant="outlined"
              fullWidth
            />

            <Input
              label="Mensagem *"
              name="msg"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
            />

            <FilePicker height={153} width={272} onChange={handleFile} />

            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              className={classes.button}
              startIcon={<AddIcon />}
              disabled={isSaving}
            >
              Adicionar
            </Button>
          </Form>

          <br />
          <hr />

          <div className={classes.postsContainer}>
            {result.map(r => (
              <ClientPost
                key={`${r.id}`}
                date={r.date}
                title={r.title}
                msg={r.msg}
                file={r.file}
                admin
                clientId={userId}
                id={r.id}
                onRemove={handleOnRemove}
              />
            ))}
          </div>

          <div ref={sentryRef}>
            <LoadMore
              eol={result.length === 0 || resultInfo.pages === resultInfo.page}
              eolTxt={
                result.length === 0
                  ? 'Não há postages'
                  : 'Não há mais postagens'
              }
            />
          </div>
        </Paper>
      )}
    </>
  );
};

export default ClientPosts;

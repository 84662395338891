import React, {useContext, useState, useCallback, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Element} from 'react-scroll';
import Carousel from 'nuka-carousel';
import parse from 'html-react-parser';

import {AlertContext} from '../../../../../App';

import api from '../../../../../services/api';
import whichError from '../../../../../services/whichError';

import './styles.css';

const Artigos = () => {
  const showAlert = useContext(AlertContext);

  // const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState([]);

  const loadData = useCallback(
    // async (filter = '', page = 1, limit = 25) => {
    async () => {
      // setIsLoading(true);

      await api
        .get(`/articles?favorites=true`)
        .then(async ({data}) => {
          if (data) {
            const {articles} = data;
            setResult(articles);
            // setIsLoading(false);
          }
        })
        .catch(e => {
          showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
          // setIsLoading(false);
        });
    },
    [showAlert],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Element name="artigos" className="artigos" id="artigos">
      <h1 className="section-title">Artigos</h1>

      <Carousel
        wrapAround
        autoplay
        autoplayInterval={5000}
        heightMode="max"
        cellAlign="center"
        slidesToShow={3}
        slidesToScroll={1}
        slideWidth="300px"
        renderBottomCenterControls={null}
        defaultControlsConfig={{
          prevButtonText: '<',
          nextButtonText: '>',
        }}
      >
        {result.map(a => (
          <div className="slide" key={`${a.id}-${a.title}`}>
            <Link to={`/artigo/${a.id}`}>
              <div className="artigos-slide-container">
                <h4>{a.title}</h4>
                <div>{parse(a.text)}</div>
              </div>
            </Link>
          </div>
        ))}
      </Carousel>

      <a href="/artigos">
        <p className="artigos-show-all">Listar Todos os Artigos</p>
      </a>
    </Element>
  );
};

export default Artigos;

import React, {useContext, useState, useCallback, useEffect} from 'react';
import parse from 'html-react-parser';

import {AlertContext} from '../../../App';

import baseURL from '../../../configs/baseURL';

import api from '../../../services/api';
import whichError from '../../../services/whichError';

import Header from '../../../components/Header';
import Footer from '../../../components/Footer';

import './styles.css';

const Artigo = ({match}) => {
  const showAlert = useContext(AlertContext);
  const {params} = match;
  const {articleId} = params;

  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState(null);

  const loadData = useCallback(async () => {
    setIsLoading(true);

    await api
      .get(`/articles/${articleId}`)
      .then(async ({data}) => {
        setResult(data);
        setIsLoading(false);
      })
      .catch(async e => {
        showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
        setIsLoading(false);
      });
  }, [articleId, showAlert]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className="artigo">
      <Header />

      {isLoading ? (
        <></>
      ) : result ? (
        <div className="artigo-container">
          <div className="artigo-imagem">
            <img
              src={`${baseURL.addr}/imgs/articles/${result.img}`}
              alt={`${result.title}`}
            />
            <p>Imagem Ilustrativa</p>
          </div>

          <div className="artigo-texto">
            <h1>{result.title}</h1>

            <h2>{result.subtitle}</h2>

            <p>{parse(result.text)}</p>
          </div>

          <hr />

          <div className="artigo-sobre">
            <p>
              Paulo Magalhães é advogado Criminalista inscrito pela OAB MG sob o
              n. 157.639. Graduado pela Universidade Estadual de Montes Claros,
              é especialista em Processo Penal, atuante principalmente em
              procedimentos da Lei de Drogas, Tribunal do Júri e Execução Penal
              <br />
              <br />
              <a href="https://wa.me/5538991285348">
                (38) 99128-5348 <span>Clique para iniciar um atendimento</span>
              </a>
            </p>
          </div>
        </div>
      ) : (
        <div className="artigo-not-found">
          <h3>Artigo não encontrado</h3>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default Artigo;

/* global FB */
import React, {useCallback, useEffect} from 'react';

const Facebook = () => {
  // criar um state CONNECTED e quando estiver true, fazer as reqs

  const connect = useCallback(async () => {
    const initFB = () => {
      const scripts = document.getElementsByTagName('script')[0];
      if (document.getElementById('facebook-jssdk')) return;

      const js = document.createElement('script');
      js.id = 'facebook-jssdk';
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      scripts.parentNode.insertBefore(js, scripts);
    };

    initFB();

    window.fbAsyncInit = () => {
      FB.init({
        appId: process.env.REACT_APP_FB_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v8.0',
      });
    };
  }, []);

  useEffect(() => {
    connect();
  }, [connect]);

  return <></>;
};

export default Facebook;

import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';

import Home from './pages/Public/Home';
import Artigo from './pages/Public/Artigo';
import ArtigosList from './pages/Public/ArtigosList';

import Client from './pages/Client';

import PrivateRoute from './components/PrivateRoute';

import Login from './pages/Auth';
import SignUp from './pages/Auth/SignUp';
import Password from './pages/Auth/Password';
import Recover from './pages/Auth/Recover';
import Logout from './pages/Auth/components/Logout';

import Admin from './pages/Admin';

import {userRoutes, admRoutes} from './pages/Admin/routes';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/artigos" exact component={ArtigosList} />
        <Route path="/artigo/:articleId" exact component={Artigo} />
        <Route path="/temp" exact component={Client} />

        {/* Rondônia */}
        <Route path="/ro/:city" component={Home} />
        <Route path="/ro" component={Home} />

        {/* Acre */}
        <Route path="/ac/:city" component={Home} />
        <Route path="/ac" component={Home} />

        {/* Amazonas */}
        <Route path="/am/:city" component={Home} />
        <Route path="/am" component={Home} />

        {/* Roraima */}
        <Route path="/rr/:city" component={Home} />
        <Route path="/rr" component={Home} />

        {/* Pará */}
        <Route path="/pa/:city" component={Home} />
        <Route path="/pa" component={Home} />

        {/* Amapá */}
        <Route path="/ap/:city" component={Home} />
        <Route path="/ap" component={Home} />

        {/* Tocantins */}
        <Route path="/to/:city" component={Home} />
        <Route path="/to" component={Home} />

        {/* Maranhão */}
        <Route path="/ma/:city" component={Home} />
        <Route path="/ma" component={Home} />

        {/* Piauí */}
        <Route path="/pi/:city" component={Home} />
        <Route path="/pi" component={Home} />

        {/* Ceará */}
        <Route path="/ce/:city" component={Home} />
        <Route path="/ce" component={Home} />

        {/* Rio Grande do Norte */}
        <Route path="/rn/:city" component={Home} />
        <Route path="/rn" component={Home} />

        {/* Paraíba */}
        <Route path="/pb/:city" component={Home} />
        <Route path="/pb" component={Home} />

        {/* Pernambuco */}
        <Route path="/pe/:city" component={Home} />
        <Route path="/pe" component={Home} />

        {/* Alagoas */}
        <Route path="/al/:city" component={Home} />
        <Route path="/al" component={Home} />

        {/* Sergipe */}
        <Route path="/se/:city" component={Home} />
        <Route path="/se" component={Home} />

        {/* Bahia */}
        <Route path="/ba/:city" component={Home} />
        <Route path="/ba" component={Home} />

        {/* Minas Gerais */}
        <Route path="/mg/:city" component={Home} />
        <Route path="/mg" component={Home} />

        {/* Espírito Santo */}
        <Route path="/es/:city" component={Home} />
        <Route path="/es" component={Home} />

        {/* Rio de Janeiro */}
        <Route path="/rj/:city" component={Home} />
        <Route path="/rj" component={Home} />

        {/* São Paulo */}
        <Route path="/sp/:city" component={Home} />
        <Route path="/sp" component={Home} />

        {/* Paraná */}
        <Route path="/pr/:city" component={Home} />
        <Route path="/pr" component={Home} />

        {/* Catarina */}
        <Route path="/sc/:city" component={Home} />
        <Route path="/sc" component={Home} />

        {/* Rio Grande do Sul */}
        <Route path="/rs/:city" component={Home} />
        <Route path="/rs" component={Home} />

        {/* Mato Grosso do Sul */}
        <Route path="/ms/:city" component={Home} />
        <Route path="/ms" component={Home} />

        {/* Mato Grosso */}
        <Route path="/mt/:city" component={Home} />
        <Route path="/mt" component={Home} />

        {/* Goiás */}
        <Route path="/go/:city" component={Home} />
        <Route path="/go" component={Home} />

        {/* Distrito Federal */}
        <Route path="/df/:city" component={Home} />
        <Route path="/df" component={Home} />

        <Route path="/login/:redirect" component={Login} />
        <Route path="/login" component={Login} />
        <Route path="/logout/:redirect" component={Logout} />
        <Route path="/logout" component={Logout} />
        <Route path="/signup" component={SignUp} />
        <Route path="/recover" component={Recover} />

        <PrivateRoute path="/password" component={Password} />

        <PrivateRoute
          path="/cliente/:clientId"
          status={[2, 3, 4]}
          exact
          component={Client}
        />

        {userRoutes.map(dr => (
          <PrivateRoute
            path={dr.path}
            exact
            component={Admin}
            key={`base:${dr.componentName}:${dr.path}`}
          />
        ))}

        {admRoutes.map(dr => (
          <PrivateRoute
            path={dr.path}
            exact
            component={Admin}
            key={`base:${dr.componentName}:${dr.path}`}
          />
        ))}
      </Switch>
    </BrowserRouter>
  );
}

import React from 'react';
import {Dots} from 'react-activity';
import 'react-activity/dist/react-activity.css';

import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    margin: '10px 0 10px 10px',
    fontWeight: 'bold',
    '& > span': {marginRight: 5},
  },
  none: {display: 'none'},
}));

const LoadMore = ({eol = true, eolTxt = 'Fim da lista.'}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {eol ? (
        eolTxt
      ) : (
        <>
          <span>Carregando mais</span>
          <Dots color="#000" speed={0.75} size={10} />
        </>
      )}
    </div>
  );
};

export default LoadMore;

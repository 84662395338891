import React, {useContext, useState, useCallback, useEffect} from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import {makeStyles} from '@material-ui/core/styles';
import Dot from '@material-ui/icons/AdjustOutlined';

import {AlertContext} from '../../App';

import api from '../../services/api';
import whichError from '../../services/whichError';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LoadMore from '../../components/LoadMore';

import ClientPost from '../../components/ClientPost';

import './styles.css';

const slo = {filter: '', page: 1, limit: 10, orderBy: 'date', sortBy: 'ASC'};

const useStyles = makeStyles({
  postsContainer: {paddingBottom: 40},
});

const Client = ({match}) => {
  const {params} = match;
  const {clientId} = params;

  const classes = useStyles();
  const showAlert = useContext(AlertContext);

  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState([]);
  const [resultInfo, setResultInfo] = useState({...slo, pages: 1, total: 0});

  const [clientName, setClientName] = useState('');
  const [fixed, setFixed] = useState(null);

  const loadData = useCallback(
    async loadOptions => {
      const options = {...slo, ...loadOptions};
      const {filter, page, limit, orderBy, sortBy, loadMore} = options;

      if (!loadMore) setIsLoading(true);

      const getClient = loadMore ? null : api.get(`/core/users/${clientId}`);
      const getPosts = api.get(
        `/core/client/${clientId}/posts?&p=${page}&l=${limit}&o=${orderBy}&s=${sortBy}&f=${filter}`,
      );

      const error = [];
      const get = await Promise.all(
        [getClient, getPosts].map((p, i) =>
          !p
            ? {data: null}
            : p.catch(e => {
                error[i] = whichError(e).errorMsg;
              }),
        ),
      );

      const errors = [...new Set(error)];
      if (errors.length > 1) {
        showAlert(
          'Atenção',
          `Ocorreram os seguintes erros:\n\n${errors.join('\n')}`,
        );
      } else if (errors.length >= 1) {
        showAlert('Erro', errors[0]);
      }

      if (errors.length > 0) return;

      if (get[0].data) {
        setClientName(get[0].data.userInformations.name);
        if (get[0].data.userInformations.fixed)
          setFixed(get[0].data.userInformations.fixed);
      }

      const {posts, ...info} = get[1].data;
      setResult(p => (loadMore ? [...p, ...posts] : posts));
      setResultInfo(info);
      setIsLoading(false);
    },
    [showAlert, clientId],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: !(resultInfo.page === resultInfo.pages),
    onLoadMore: () => loadData({page: resultInfo.page + 1, loadMore: true}),
    // disabled: !!i,
    rootMargin: '0px 0px 10px 0px',
  });

  return (
    <div className="client">
      <Header />

      <div className="client-container">
        <h1>Área do Cliente</h1>

        {isLoading ? (
          <></>
        ) : (
          <>
            <h2>{clientName}</h2>

            {fixed && (
              <div className="client-fixed-msg">
                <hr />
                <h3>Mensagem Fixada</h3>

                <div className="client-fixed-msg-content">
                  <Dot />
                  <p>{fixed}</p>
                </div>

                <hr />
              </div>
            )}

            <div className={classes.postsContainer}>
              {result.map(r => (
                <ClientPost
                  key={`${r.id}`}
                  date={r.date}
                  title={r.title}
                  msg={r.msg}
                  file={r.file}
                  clientId={clientId}
                  id={r.id}
                />
              ))}
            </div>

            <div ref={sentryRef}>
              <LoadMore
                eol={
                  result.length === 0 || resultInfo.pages === resultInfo.page
                }
                eolTxt={
                  result.length === 0
                    ? 'Não há postages'
                    : 'Não há mais postagens'
                }
              />
            </div>
          </>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default Client;

import React, {useContext, useState, useCallback, useEffect} from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import {format} from 'date-fns';

import {makeStyles} from '@material-ui/core/styles';

import {AlertContext} from '../../../App';

import baseURL from '../../../configs/baseURL';

import api from '../../../services/api';
import whichError from '../../../services/whichError';

import Header from '../../../components/Header';
import Timeline from '../../../components/Timeline';
import LoadMore from '../../../components/LoadMore';
import Footer from '../../../components/Footer';

import './styles.css';

const slo = {filter: '', page: 1, limit: 10, orderBy: 'date', sortBy: 'ASC'};

const useStyles = makeStyles({
  postsContainer: {paddingBottom: 40},
});

const ArtigosList = () => {
  const showAlert = useContext(AlertContext);
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState([]);
  const [resultInfo, setResultInfo] = useState({...slo, pages: 1, total: 0});

  const loadData = useCallback(
    async loadOptions => {
      const options = {...slo, ...loadOptions};
      const {filter, page, limit, orderBy, sortBy, loadMore} = options;

      if (!loadMore) setIsLoading(true);

      await api
        .get(
          `/articles?p=${page}&l=${limit}&o=${orderBy}&s=${sortBy}&f=${filter}`,
        )
        .then(async ({data}) => {
          if (data) {
            const {articles, ...info} = data;
            setResult(p => (loadMore ? [...p, ...articles] : articles));
            setResultInfo(info);
            setIsLoading(false);
          }
        })
        .catch(e => {
          showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
          setIsLoading(false);
        });
    },
    [showAlert],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: !(resultInfo.page === resultInfo.pages),
    onLoadMore: () => loadData({page: resultInfo.page + 1, loadMore: true}),
    // disabled: !!i,
    rootMargin: '0px 0px 10px 0px',
  });

  return (
    <div className="artigos-list">
      <Header />

      <div className="artigos-list-container">
        <h1>Lista de Artigos</h1>

        {isLoading ? (
          <></>
        ) : (
          <>
            <div className={classes.postsContainer}>
              {result.map(a => (
                <a href={`/artigo/${a.id}`} key={`${a.id}`}>
                  <div className="artigos-list-item">
                    <Timeline />

                    <div className="artigos-list-item-content">
                      <div className="artigos-list-item-data">
                        {format(new Date(a.createdAt), 'dd/MM/yyyy HH:mm')}
                      </div>

                      <div className="artigos-list-item-title">{a.title}</div>

                      <div className="artigos-list-item-card">
                        <div className="artigos-list-item-imagem">
                          <img
                            src={`${baseURL.addr}/imgs/articles/${a.img}`}
                            alt={`${result.title}`}
                          />
                        </div>

                        <div className="artigos-list-item-texto">
                          {a.subtitle}
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              ))}
            </div>

            <div ref={sentryRef}>
              <LoadMore
                eol={
                  result.length === 0 || resultInfo.pages === resultInfo.page
                }
                eolTxt={
                  result.length === 0
                    ? 'Não há artigos postados'
                    : 'Fim da lista de artigos'
                }
              />
            </div>
          </>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default ArtigosList;

import React, {useState, useEffect} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {Spinner} from 'react-activity';

import {useDispatch, useSelector} from 'react-redux';
import * as loginActions from '../store/actions/login';

import api from '../services/api';

export const Loading = () => (
  <div
    style={{
      height: '100%',
      display: 'flex',
      padding: 20,
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Spinner />
  </div>
);

const PrivateRoute = ({component: Component, status = null, ...rest}) => {
  const dispatch = useDispatch();
  const Login = useSelector(state => state.Login);

  const [gotAnswer, setGotAnswer] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    const cleanLogin = () => {
      delete api.defaults.headers.common.user;
      delete api.defaults.headers.common.apptoken;
      delete api.defaults.headers.common.stoken;

      localStorage.removeItem('user');
      localStorage.removeItem('apptoken');
      localStorage.removeItem('stoken');

      dispatch(loginActions.resetLogin());
    };

    const verifyToken = async () => {
      const {userId, sToken, status: userStatus} = Login;
      const token = localStorage.getItem('apptoken');

      if (!userId || !token || !sToken) {
        cleanLogin();
        setIsAuthenticated(false);
        return setGotAnswer(true);
      }

      const verify = await api
        .get('/auth/jwt', {headers: {apptoken: token, stoken: sToken}})
        .catch(() => null);

      if (!verify) {
        cleanLogin();
        setIsAuthenticated(false);
        return setGotAnswer(true);
      }

      api.defaults.headers.common.apptoken = verify.data.token;
      api.defaults.headers.common.stoken = sToken;
      api.defaults.headers.common.user = userId;

      localStorage.setItem('apptoken', verify.data.token);

      setIsAuthenticated(true);

      if (status) {
        if (status.indexOf(userStatus) < 0) {
          setIsAllowed(false);
          return setGotAnswer(true);
        }
      }

      setIsAllowed(true);
      return setGotAnswer(true);
    };

    verifyToken();
  }, [Login, dispatch, status]);

  return (
    <Route
      {...rest}
      render={props =>
        !gotAnswer ? (
          <Loading />
        ) : isAuthenticated && isAllowed ? (
          <Component {...props} />
        ) : (
          <>
            {isAuthenticated && !isAllowed && (
              <Redirect
                to={{pathname: '/dashboard', state: {from: props.location}}}
              />
            )}

            {!isAuthenticated && (
              <Redirect
                to={{pathname: '/login', state: {from: props.location}}}
              />
            )}
          </>
        )
      }
    />
  );
};

export default PrivateRoute;

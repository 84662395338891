import React, {
  useContext,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import {Form} from '@unform/web';
import * as Yup from 'yup';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {convertToRaw, EditorState, ContentState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {makeStyles} from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Input from '../../../../components/Form/Input';
import ImgPicker from '../../../../components/ImgPicker';

import Header from '../../components/Header';

import './styles.css';

Numeral.locale('pt-br');

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    // maxWidth: 550,
    padding: theme.spacing(2),
  },

  button: {marginRight: 20},

  favorite: {
    textAlign: 'right',
    backgroundColor: 'blue',
    width: 10,
    height: 10,
  },

  textInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const Article = ({history, match}) => {
  const showAlert = useContext(AlertContext);
  const {params} = match;
  const {articleId} = params;

  const classes = useStyles();
  const formRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState(null);

  const [img, setImg] = useState(null);
  const [imgAction, setImgAction] = useState(false);

  const [fav, setFav] = useState(false);

  const [text, setText] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  const loadData = useCallback(async () => {
    setIsLoading(true);

    await api
      .get(`/core/articles/${articleId}`)
      .then(async ({data}) => {
        setResult(data);
        setFav(!!data.favorite);
        const blocksFromHtml = htmlToDraft(data.text);
        const {contentBlocks, entityMap} = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap,
        );
        const editorState = EditorState.createWithContent(contentState);
        setText(editorState);

        setIsLoading(false);
      })
      .catch(async e => {
        showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
        setIsLoading(false);
      });
  }, [articleId, showAlert]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSubmit = async (unformData, {reset}) => {
    formRef.current.setErrors({});

    const schema = Yup.object().shape({
      path: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .default(undefined),
      title: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .min(1, 'O nome deve ter no mínimo 1 caractere.')
        .required('Favor informar o título do artigo'),
      subtitle: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .min(1, 'O nome deve ter no mínimo 1 caractere.')
        .required('Favor informar o subtítulo do artigo'),
    });

    try {
      await schema.validate(unformData, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }

      return;
    }

    setIsSaving(true);

    const data = schema.cast(unformData);

    data.favorite = fav;

    const rawContentState = convertToRaw(text.getCurrentContent());
    data.text = draftToHtml(rawContentState);

    const userHasImg = !!result.img;
    data.imgAction =
      userHasImg && imgAction && img === null
        ? 'remove'
        : userHasImg && !imgAction && img === null
        ? 'keep'
        : 'update';

    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.append('formData', JSON.stringify(data));

    if (img) formData.append('img', img);

    const save = await api
      .put(`/core/articles/${result.id}`, formData)
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    setIsSaving(false);

    if (save) {
      reset();
      history.goBack();
    }
  };

  const handleDelete = () =>
    api
      .delete(`/core/articles/${result.id}`)
      .then(() => history.goBack())
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

  const handleAskDelete = () => {
    showAlert({
      title: 'Confirmação',
      msg: 'Deseja realmente apagar este artigo?',
      onOk: handleDelete,
    });
  };

  const handleImg = image => {
    setImgAction(true);
    setImg(image);
  };

  const handleFavorite = () => setFav(p => !p);

  function converterString(str) {
    let novaStr = str.toLowerCase();
    novaStr = novaStr.replace(/\s+/g, '-');
    novaStr = novaStr.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    novaStr = novaStr.replace(/[.,;!?^$#@&*()+=~`{}[\]|\\<>'"/]/g, '');
    return novaStr;
  }

  const handleTransformPath = useCallback(() => {
    const {title} = formRef.current.getData();
    formRef?.current?.setFieldValue('path', converterString(title));
  }, []);

  return (
    <>
      <Header title="Artigo" />

      <Paper className={classes.root}>
        {isLoading ? (
          <></>
        ) : (
          result && (
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={{
                title: result.title,
                path: result.path,
                subtitle: result.subtitle,
              }}
            >
              <IconButton onClick={handleFavorite}>
                {fav ? (
                  <StarIcon fontSize="large" />
                ) : (
                  <StarBorderIcon fontSize="large" />
                )}
              </IconButton>

              <ImgPicker
                height={150}
                width={150}
                onChange={handleImg}
                type="articles"
                image={result.img}
              />

              <Input
                label="Título *"
                name="title"
                className={classes.textInput}
                variant="outlined"
                fullWidth
              />

              <Input
                label="Caminho da URL*"
                name="path"
                className={classes.textInput}
                variant="outlined"
                fullWidth
              >
                <button type="button" onClick={handleTransformPath}>
                  Transformar título
                </button>
              </Input>

              <Input
                label="Subtítulo"
                name="subtitle"
                className={classes.textInput}
                variant="outlined"
                fullWidth
              />

              <Editor
                editorState={text}
                toolbarClassName="article-editor-toolbar"
                wrapperClassName="article-editor-wrapper"
                editorClassName="article-editor"
                onEditorStateChange={setText}
              />

              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                className={classes.button}
                startIcon={<EditIcon />}
                disabled={isSaving}
              >
                Salvar alterações
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={handleAskDelete}
                size="large"
                className={classes.button}
                startIcon={<DeleteForeverIcon />}
                disabled={isSaving}
              >
                Apagar Artigo
              </Button>
            </Form>
          )
        )}
      </Paper>
    </>
  );
};

export default Article;

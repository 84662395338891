import React from 'react';
import {Element} from 'react-scroll';

import pm from '../../../../../assets/pm2.jpg';

import './styles.css';

const Sobre = () => (
  <Element name="sobre" className="sobre" id="sobre">
    <div className="sobre-container">
      <p>
        Paulo Magalhães é advogado Criminalista inscrito pela OAB MG sob o n.
        157.639. Graduado pela Universidade Estadual de Montes Claros, é
        especialista em Processo Penal, atuante principalmente em procedimentos
        da Lei de Drogas, Tribunal do Júri e Execução Penal
        <br />
        <br />
        <a href="https://wa.me/5538991285348">
          (38) 99128-5348 <span>Clique para iniciar um atendimento</span>
        </a>
      </p>

      <div className="sobre-img">
        <img src={pm} alt="Paulo Magalhães" />
      </div>
    </div>
  </Element>
);

export default Sobre;

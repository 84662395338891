import React, {useContext, useRef, useState} from 'react';
import {Form} from '@unform/web';
import * as Yup from 'yup';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';

import {AlertContext} from '../../../../../App';

import api from '../../../../../services/api';
import whichError from '../../../../../services/whichError';

import Input from '../../../../../components/Form/Input';
import Switch from '../../../../../components/Form/Switch';

const Email = ({clientId, onClose = () => null}) => {
  const showAlert = useContext(AlertContext);
  const formRef = useRef(null);

  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = async (unformData, {reset}) => {
    formRef.current.setErrors({});

    const schema = Yup.object().shape({
      email: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .min(1, 'Informe um e-mail válido')
        .email('Informe um e-mail válido')
        .required('Informe o e-mail'),
    });

    try {
      await schema.validate(unformData, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }

      return;
    }

    const data = schema.cast(unformData);
    data.skipEmailConfirmation = true;
    data.userId = clientId;

    setIsSaving(true);

    const save = await api
      .post(`/core/useremails`, data)
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    setIsSaving(false);

    if (save) {
      reset();
      onClose(true);
    }
  };

  const handleOnClose = () => onClose(false);

  return (
    <Dialog
      open={!!clientId}
      onClose={() => (isSaving ? null : onClose(false))}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">E-mail do Cliente</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Informe o e-mail que deseja cadastrar.
        </DialogContentText>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input
            label="E-mail *"
            name="email"
            fullWidth
            disabled={isSaving}
            variant="outlined"
          />

          <Switch label="Principal" name="isDefault" />
        </Form>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleOnClose}
          variant="contained"
          color="primary"
          disabled={isSaving}
        >
          Cancelar
        </Button>

        <Button
          onClick={() => formRef.current.submitForm()}
          variant="contained"
          color="primary"
          disabled={isSaving}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Email;

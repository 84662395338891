import React from 'react';
import {Link} from 'react-router-dom';

import logo from '../../assets/logo.png';

import './styles.css';

const Principal = () => {
  const appToken = localStorage.getItem('apptoken');

  return (
    <div className="footer">
      <div className="links">
        <div className="footer-section">
          <p>
            Rua João F. Pimenta, 287a, Cidade Santa Maria - Montes Claros/MG -
            CEP 39.401-081 <br /> (38) 99128-5348
          </p>
        </div>

        <div className="footer-section footer-section-links">
          <p>
            <Link to="/#sobre">Sobre</Link>
          </p>
          <p>
            <Link to="/artigos">Artigos</Link>
          </p>
          <p>
            <Link to="/#servicos">Serviços</Link>
          </p>
        </div>

        <div className="footer-section footer-section-links">
          <p>
            <Link to="/#contato">Contato</Link>
          </p>
          <p>
            <Link to="/#mapa">Localização</Link>
          </p>
          <p>
            {appToken ? (
              <Link to="/logout">Desconectar</Link>
            ) : (
              <Link to="/login">Área do Cliente</Link>
            )}
          </p>
        </div>

        <div className="footer-section icon-sa">
          <img src={logo} alt="Paulo Magalhães" />
        </div>
      </div>

      <div className="footer-made-by">
        <span>
          developed and designed by: <a href="https://fruges.dev">fruges.dev</a>
        </span>
      </div>
    </div>
  );
};

export default Principal;

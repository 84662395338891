import React, {useContext, useState} from 'react';

import IconButton from '@material-ui/core/IconButton';

import AddBoxIcon from '@material-ui/icons/AddBox';
import DelIcon from '@material-ui/icons/DeleteForeverOutlined';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import {AlertContext} from '../../../../../App';

import api from '../../../../../services/api';
import whichError from '../../../../../services/whichError';

import Email from './Email';

import useStyles from '../styles';

const Emails = ({
  userEmails = false,
  userId = false,
  edit = false,
  // editing = false,
  handleEdit = () => null,
  handleEditing = () => null,
  handleLoadData = () => null,
}) => {
  const classes = useStyles();
  const showAlert = useContext(AlertContext);

  const [emailDialog, setEmailDialog] = useState(false);

  const handleAddEmail = () => setEmailDialog(userId);

  const handleOnCloseEmailDialog = (refresh = false) => {
    setEmailDialog(false);
    if (refresh) handleLoadData();
  };

  const handleDefault = async email2SetDefault => {
    handleEditing(true);

    const setEmailDefault = await api
      .put(`/core/useremail/${email2SetDefault}`, {isDefault: true})
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    if (setEmailDefault) handleLoadData();

    handleEditing(false);
    handleEdit(false);
  };

  const handleAskDefault = email2Default => {
    showAlert({
      title: 'Confirmação',
      msg: `Deseja marcar como principal o e-mail ${email2Default}?`,
      onOk: () => handleDefault(email2Default),
    });
  };

  const handleDelEmail = async email2Destroy => {
    handleEditing(true);

    const delEmail = await api
      .delete(`/core/useremail/${email2Destroy}`)
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    if (delEmail) handleLoadData();

    handleEditing(false);
    handleEdit(false);
  };

  const handleAskDelEmail = email2Del => {
    showAlert({
      title: 'Confirmação',
      msg: `Deseja apagar o e-mail ${email2Del}?`,
      onOk: () => handleDelEmail(email2Del),
    });
  };

  return (
    <div className={classes.contacts}>
      <div className={classes.contactsHeader}>
        <span>E-mails </span>

        <IconButton
          size="small"
          onClick={handleAddEmail}
          className={classes.contactAddBtn}
          color="primary"
        >
          <AddBoxIcon fontSize="large" />
        </IconButton>
      </div>

      {userEmails.map(e => (
        <div className={classes.contactItem} key={`${e.email}`}>
          {e.default ? (
            <IconButton
              size="small"
              className={classes.contactFav}
              color="primary"
            >
              <StarIcon />
            </IconButton>
          ) : (
            <IconButton
              size="small"
              onClick={() => (edit ? handleAskDefault(e.email) : null)}
              className={classes.contactFav}
              color="primary"
            >
              <StarBorderIcon />
            </IconButton>
          )}

          <span>{e.email}</span>

          {edit && (
            <IconButton
              size="small"
              onClick={() => handleAskDelEmail(e.email)}
              className={classes.contactEditIcon}
              color="primary"
            >
              <DelIcon />
            </IconButton>
          )}
        </div>
      ))}

      {userEmails.length === 0 && (
        <span className={classes.contactEmpty}>
          - Nenhum e-mail cadastrado -
        </span>
      )}

      <Email clientId={emailDialog} onClose={handleOnCloseEmailDialog} />
    </div>
  );
};

export default Emails;

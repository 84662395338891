import React from 'react';
import {Element} from 'react-scroll';
// import { GoogleMap, LoadScript } from "@react-google-maps/api";

import './styles.css';

const Mapa = () => {
  return (
    <Element name="mapa" className="mapa" id="mapa">
      {/* <LoadScript googleMapsApiKey="AIzaSyBYjsSMVM1SJnh24WZdQ-5I2udB_lJ6p5M">
      <GoogleMap
        mapContainerStyle={{
          width: "100%",
          height: "400px",
        }}
        center={{
          lat: -16.7413594,
          lng: -43.8832214,
        }}
        zoom={15}
      >
        <></>
      </GoogleMap>
    </LoadScript> */}
      <iframe
        title="Google Maps"
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCL5URexgc7aYas58fuenbB7Y8k4mRmTVY&q=PAULO+MAGALHÃES+-+ADVOCACIA+E+CONSULTORIA+JURÍDICA"
        width="100%"
        height="400"
        style={{border: 0, marginBottom: -5}}
        className="mapa-map"
      />
    </Element>
  );
};

export default Mapa;

import React from 'react';
import {Link} from 'react-router-dom';
import Dropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';
import MenuIcon from '@material-ui/icons/Menu';

import logo from '../../assets/logo.png';
import pm from '../../assets/pm.png';

import NavMenu from '../NavMenu';

import './styles.css';

const Header = () => {
  const appToken = localStorage.getItem('apptoken');

  return (
    <header className="principal-header">
      <a href="/">
        <img
          src={logo}
          className="principal-logo"
          alt="Paulo Magalhães - Advogado Criminalista"
        />
      </a>

      <div className="header-nav">
        <ul>
          <li>
            <Link to="/#sobre">Sobre</Link>
          </li>

          <li>
            <Link to="/artigos">Artigos</Link>
          </li>

          <li>
            <Link to="/#servicos">Serviços</Link>
          </li>

          <li>
            <Link to="/#contato">Contato</Link>
          </li>

          <li>
            <Link to="/#mapa">Localização</Link>
          </li>

          <li>
            {appToken ? (
              <Link to="/logout">Desconectar</Link>
            ) : (
              <Link to="/login">Área do Cliente</Link>
            )}
          </li>
        </ul>
      </div>

      <div className="header-pm">
        <img src={pm} alt="Paulo Magalhães" />
      </div>

      <div className="responsive-menu">
        <Dropdown
          arrow
          trigger={['click']}
          overlay={NavMenu}
          animation="slide-up"
        >
          <button onClick={null} className="menu-btn" type="button">
            <MenuIcon fontSize="large" />
          </button>
        </Dropdown>
      </div>
    </header>
  );
};

export default Header;

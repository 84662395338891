import React from 'react';
import clsx from 'clsx';

import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
// import InputAdornment from '@material-ui/core/InputAdornment';

// import IconButton from '@material-ui/core/IconButton';
// import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    marginTop: theme.spacing(2),
  },
  textField: {
    width: '20ch',
  },
}));

const Search = ({onChange}) => {
  const classes = useStyles();

  const handleInput = event => {
    const {value} = event.target;
    onChange(value);
  };

  return (
    <FormControl className={clsx(classes.margin, classes.textField)}>
      <InputLabel htmlFor="standard-search">Pesquisa</InputLabel>
      <Input
        id="standard-search"
        // autoComplete={false} //https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill
        // autoCorrect={false}
        // autoCapitalize={}
        onChange={handleInput}
        // startAdornment={
        //   <InputAdornment position="end">
        //       <SearchIcon />
        //     {/* <IconButton onClick={handleInput} onMouseDown={handleInput}>
        //     </IconButton> */}
        //   </InputAdornment>
        // }
        // endAdornment={
        //   <InputAdornment position="end">
        //     <IconButton
        //       aria-label="toggle password visibility"
        //       onClick={handleClickShowPassword}
        //       onMouseDown={handleMouseDownPassword}
        //     >
        //       {values.showPassword ? <Visibility /> : <VisibilityOff />}
        //     </IconButton>
        //   </InputAdornment>
        // }
      />
    </FormControl>
  );
};

export default Search;

import React, {useContext, useState, useCallback, useEffect} from 'react';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {makeStyles} from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Header from '../../components/Header';
import Loading from '../../components/Loading';

Numeral.locale('pt-br');

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 550,
    padding: theme.spacing(2),
  },

  optionsBtns: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const Manager = ({match}) => {
  const showAlert = useContext(AlertContext);
  const {params} = match;
  const {userId} = params;

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState(null);

  const loadData = useCallback(async () => {
    setIsLoading(true);

    await api
      .get(`/core/users/${userId}`)
      .then(async res => {
        setResult(res.data);
        setIsLoading(false);
      })
      .catch(async e => {
        showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
        setIsLoading(false);
      });
  }, [userId, showAlert]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      <Header title="Detalhes do Gerente" />

      <Paper className={classes.root}>
        {isLoading ? (
          <Loading />
        ) : !result ? (
          // <p>REDIRECIONAR</p>
          <></>
        ) : (
          <>
            <Typography color="textPrimary" variant="h5">
              {result.userInformations.name}
            </Typography>

            <br />

            <Typography variant="h6">E-mails</Typography>
            {result.userEmails.map(ue => (
              <Typography key={ue.email}>{ue.email}</Typography>
            ))}

            <Box mt={3} />
            <Divider pt={2} />
            <Box mt={3} />
          </>
        )}
      </Paper>
    </>
  );
};

export default Manager;

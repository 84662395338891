import React, {useContext} from 'react';
import {format} from 'date-fns';

import IconButton from '@material-ui/core/IconButton';
import DelIcon from '@material-ui/icons/HighlightOff';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import baseURL from '../../configs/baseURL';

import api from '../../services/api';
import whichError from '../../services/whichError';
import {AlertContext} from '../../App';

import Timeline from '../Timeline';

import './styles.css';

const ClientPost = ({
  date,
  title,
  msg,
  file = false,
  admin = false,
  clientId,
  id,
  onRemove = () => null,
}) => {
  const showAlert = useContext(AlertContext);

  const handleDel = async () => {
    await api
      .delete(`/core/client/${clientId}/posts/${id}`)
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    onRemove(id);
  };

  const handleAskDel = () => {
    showAlert({
      title: 'Confirmação',
      msg: 'Deseja realmente apagar esta postagem?',
      onOk: handleDel,
    });
  };

  return (
    <div className="client-item">
      <Timeline />

      <div className="client-item-content">
        <div className="client-item-data">
          {format(new Date(date), 'dd/MM/yyyy HH:mm')}

          {admin && (
            <IconButton
              onClick={handleAskDel}
              color="primary"
              size="small"
              className="client-item-admin-del"
            >
              <DelIcon fontSize="small" />
            </IconButton>
          )}
        </div>

        <div className="client-item-title">{title}</div>

        <div className="client-item-content">{msg}</div>

        {file && (
          <a href={`${baseURL.addr}/docs/${file}`}>
            <div className="client-item-file">
              <FileCopyIcon
                className="client-item-file-icon "
                fontSize="large"
              />
               <span>Baixar arquivo</span>
            </div>
          </a>
        )}
      </div>
    </div>
  );
};

export default ClientPost;

// import React, {createContext, useRef, useMemo} from 'react';
import React, {createContext, useRef} from 'react';
// import socketio from 'socket.io-client';
// import {useSelector} from 'react-redux';
import {Helmet} from 'react-helmet';

import CssBaseline from '@material-ui/core/CssBaseline';

// import baseURL from './configs/baseURL';

import Facebook from './components/Facebook';
import AlertDialog from './components/AlertDialog';

import Routes from './routes';

export const SocketContext = createContext(null);
export const AlertContext = createContext(null);

export default function App() {
  const alert = useRef();
  // const {Login} = useSelector(state => state);
  // const {userId} = Login;

  // const socket = useMemo(
  //   () =>
  //     socketio(baseURL.socket, {
  //       query: {userId},
  //     }),
  //   [userId],
  // );

  // socket.on('alertMsg', async ({title, msg}) => {
  //   alert.current.set({title, msg});
  // });

  return (
    <>
      <Helmet>
        <title>Paulo Magalhães - Advogado Criminalista</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Paulo Magalhães - Advogado Criminalista" />
        <meta
          name="description"
          content="Advogado Criminalista especialista em Processo Penal, atuante principalmente em procedimentos da Lei de Drogas, Tribunal do Júri e Execução Penal"
        />
        <meta
          name="keywords"
          content="Advogado,Criminalista,Acompanhamento,de,Prisões,em,Flagrante,Inquéritos,Policiais,Defesas,Judiciais,Administrativas,Pedidos,Liberdade,Execução,Penal,Tribunal,Juri,Revisão,Criminal,Recursos,Sustentações,Orais,Advocacia,Alta,Complexidade"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta name="revisit-after" content="10 days" />
        <meta name="author" content="fruges.dev" />
        <link rel="canonical" href="https://paulomagalhaesadv.com" />
      </Helmet>

      <CssBaseline />

      <Facebook />

      <AlertDialog ref={alert} />

      <AlertContext.Provider value={params => alert.current.set(params)}>
        {/* <SocketContext.Provider value={socket}> */}
        <Routes />
        {/* </SocketContext.Provider> */}
      </AlertContext.Provider>
    </>
  );
}

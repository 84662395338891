import React, {useEffect, useRef} from 'react';
import {useField} from '@unform/core';

export default function Input({name, multiline = false, ...rest}) {
  const inputRef = useRef(null);

  const {fieldName, defaultValue, registerField, error} = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <div className="form-input-container">
      {multiline ? (
        <textarea ref={inputRef} defaultValue={defaultValue} {...rest} />
      ) : (
        <input ref={inputRef} defaultValue={defaultValue} {...rest} />
      )}
      {<span className="form-error">{error || ''}</span>}
    </div>
  );
}

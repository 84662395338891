import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';

const City = ({uf, city, estado}) => {
  const [ufCity, setUfCity] = useState(null);

  useEffect(() => {
    const getCities = async () => {
      const ufData = await import(`../../../../../constants/ibge/${uf}.json`);
      if (!ufData) return;

      setUfCity(ufData?.[city] || null);
    };

    getCities();
  }, [city, uf]);

  return ufCity ? (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Paulo Magalhães - Advogado Criminalista - {ufCity} - {estado}
        </title>
        <link
          rel="canonical"
          href={`https://paulomagalhaesadv.com/${uf}/${city}`}
        />
      </Helmet>
      <span>
        {ufCity}/{uf.toUpperCase()}
      </span>
    </>
  ) : (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Paulo Magalhães - Advogado Criminalista - {estado}</title>
        <link rel="canonical" href={`https://paulomagalhaesadv.com/${uf}`} />
      </Helmet>
      <span>{estado}</span>
    </>
  );
};

export default City;

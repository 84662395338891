import {useState} from 'react';

const useToggle = initialValue => {
  const [value, setValue] = useState(initialValue);

  const toggleValue = action => {
    if (action === 'close') {
      setValue(false);
      return;
    }

    if (action === 'open') {
      setValue(true);
      return;
    }

    setValue(!value);
  };

  return [value, toggleValue];
};

export {useToggle};

import React, {useContext, useState} from 'react';

import IconButton from '@material-ui/core/IconButton';

import AddBoxIcon from '@material-ui/icons/AddBox';
import DelIcon from '@material-ui/icons/DeleteForeverOutlined';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import {AlertContext} from '../../../../../App';

import api from '../../../../../services/api';
import whichError from '../../../../../services/whichError';

import Phone from './Phone';

import useStyles from '../styles';

const Phones = ({
  userPhones = false,
  userId = false,
  edit = false,
  // editing = false,
  handleEdit = () => null,
  handleEditing = () => null,
  handleLoadData = () => null,
}) => {
  const classes = useStyles();
  const showAlert = useContext(AlertContext);

  const [dialog, setDialog] = useState(false);

  const handleAdd = () => setDialog(userId);

  const handleOnCloseDialog = (refresh = false) => {
    setDialog(false);
    if (refresh) handleLoadData();
  };

  const handleDefault = async (ddi2Def, phone2Def) => {
    handleEditing(true);

    const setDefault = await api
      .put(`/core/userphone/${phone2Def}/${ddi2Def}`, {isDefault: true})
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    if (setDefault) handleLoadData();

    handleEditing(false);
    handleEdit(false);
  };

  const handleAskDefault = (ddiDef, phoneDef) => {
    showAlert({
      title: 'Confirmação',
      msg: `Deseja marcar como principal o telefone +(${ddiDef}) ${phoneDef}?`,
      onOk: () => handleDefault(ddiDef, phoneDef),
    });
  };

  const handleDel = async (ddi2Destroy, phone2Destroy) => {
    handleEditing(true);

    const delEmail = await api
      .delete(`/core/userphone/${phone2Destroy}/${ddi2Destroy}`)
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    if (delEmail) handleLoadData();

    handleEditing(false);
    handleEdit(false);
  };

  const handleAskDel = (ddi2Del, phone2Del) => {
    showAlert({
      title: 'Confirmação',
      msg: `Deseja apagar o telefone +(${ddi2Del}) ${phone2Del}?`,
      onOk: () => handleDel(ddi2Del, phone2Del),
    });
  };

  return (
    <div className={classes.contacts}>
      <div className={classes.contactsHeader}>
        <span>Telefones </span>

        <IconButton
          size="small"
          onClick={handleAdd}
          className={classes.contactAddBtn}
          color="primary"
        >
          <AddBoxIcon fontSize="large" />
        </IconButton>
      </div>

      {userPhones.map(e => (
        <div className={classes.contactItem} key={`${e.phone}`}>
          {e.default ? (
            <IconButton
              size="small"
              className={classes.contactFav}
              color="primary"
            >
              <StarIcon />
            </IconButton>
          ) : (
            <IconButton
              size="small"
              onClick={() => (edit ? handleAskDefault(e.ddi, e.phone) : null)}
              className={classes.contactFav}
              color="primary"
            >
              <StarBorderIcon />
            </IconButton>
          )}

          <span>
            +({e.ddi}) {e.phone}
          </span>

          {edit && (
            <IconButton
              size="small"
              onClick={() => handleAskDel(e.ddi, e.phone)}
              className={classes.contactEditIcon}
              color="primary"
            >
              <DelIcon />
            </IconButton>
          )}
        </div>
      ))}

      {userPhones.length === 0 && (
        <span className={classes.contactEmpty}>
          - Nenhum teleofone cadastrado -
        </span>
      )}

      <Phone clientId={dialog} onClose={handleOnCloseDialog} />
    </div>
  );
};

export default Phones;

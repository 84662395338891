import React, {useEffect, useMemo} from 'react';
import {scroller} from 'react-scroll';

import Principal from './components/Principal';
import Sobre from './components/Sobre';
import Artigos from './components/Artigos';
import Servicos from './components/Servicos';
import Contato from './components/Contato';
import Mapa from './components/Mapa';

import Footer from '../../../components/Footer';

import './styles.css';
import WhatsAppBtn from './components/WhatsAppBtn/WhatsAppBtn';
import AnyCity from './components/AnyCity/AnyCity';

const Home = ({location, match}) => {
  const {params, path} = match || {};
  const {city: c} = params || {};

  const uf = useMemo(
    () => (path === '/' ? undefined : `${path}`.substring(1, 3)),
    [path],
  );
  const city = useMemo(() => c, [c]);

  useEffect(() => {
    if (location.hash) {
      // let elem = document.getElementById(location.hash.slice(1));
      // if (elem) elem.scrollIntoView({ behavior: "smooth" });
      const elem = location.hash.slice(1);
      scroller.scrollTo(elem, {smooth: true, offset: 0, delay: 420});
    }
  }, [location]);

  return (
    <div className="fruges">
      <Principal />
      <AnyCity {...{uf, city}} />
      <Sobre />
      <Artigos />
      <Servicos />
      <Contato />
      <Mapa />
      <Footer />
      <WhatsAppBtn />
    </div>
  );
};

export default Home;

// useStyles.js
import {makeStyles} from '@material-ui/core/styles';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  drawerList: {
    width: '100%',
    maxWidth: drawerWidth,
    backgroundColor: theme.palette.background.paper,
  },

  nestedListItem: {
    paddingLeft: theme.spacing(3),
  },
}));

export default useStyles;

import React, {useRef, useState, useEffect} from 'react';
import {useField} from '@unform/core';

import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1),
  },
}));

const TextInput = ({
  name,
  label,
  multiline = false,
  rows = 1,
  inputProps = {},
  readOnly = false,
  children,
  ...rest
}) => {
  const classes = useStyles();
  const inputRef = useRef(null);

  const {fieldName, defaultValue, registerField, error} = useField(name);

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',

      getValue: () => inputValue,
      setValue: (_ref, value) => setInputValue(`${value}`),
      clearValue: () => setInputValue(''),
    });
  }, [fieldName, inputValue, registerField]);

  const handleChange = ({target}) => {
    const {value} = target;
    setInputValue(value);
  };

  useEffect(() => {
    if (defaultValue) {
      setInputValue(`${defaultValue}`);
    }
  }, [defaultValue]);

  return (
    <div className={classes.root}>
      <FormControl {...rest}>
        <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>

        <OutlinedInput
          ref={inputRef}
          label={label}
          value={inputValue}
          multiline={multiline}
          rows={rows}
          inputProps={inputProps}
          readOnly={readOnly}
          error={!!error}
          onChange={handleChange}
        />

        {children}

        <FormHelperText>{error || ' '}</FormHelperText>
      </FormControl>
    </div>
  );
};

export default TextInput;

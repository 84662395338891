/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useRef, useMemo, useState} from 'react';

import {makeStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DelIcon from '@material-ui/icons/HighlightOffTwoTone';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },

  label: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 155,
    height: 65,
    marginTop: 10,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#ddd',
    borderStyle: 'dashed',
    borderRadius: 5,
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    fontSize: 12,
    fontStyle: 'italic',
  },

  noBorder: {
    borderWidth: 0,
  },

  none: {
    display: 'none',
  },

  filename: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: 4,
    //   whiteSpace: "nowrap",
  },

  delBtn: {
    position: 'absolute',
    marginLeft: 155,
    marginBottom: 65,
  },
});

const FilePicker = ({
  file = null,
  disabled = false,
  className = null,
  onChange = () => null,
}) => {
  const input = useRef();
  const classes = useStyles();

  const [fileName, setFileName] = useState(null);

  const label = useMemo(() => fileName || file || false, [fileName, file]);

  const handleOnChange = ({target}) => {
    const selectedFile = target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name || null);
      onChange(selectedFile || null);
    }
  };

  const handleDelFile = () => {
    input.current.value = null;
    setFileName(null);
    onChange(null);
  };

  return (
    <div className={className}>
      <div className={classes.root}>
        <label className={classes.label}>
          <input
            type="file"
            ref={input}
            onChange={handleOnChange}
            className={classes.none}
            disabled={disabled}
          />

          {label ? (
            <p className={classes.filename}>{label}</p>
          ) : (
            <FileCopyIcon />
          )}
        </label>

        {(fileName || file) && !disabled && (
          <IconButton
            className={classes.delBtn}
            onClick={handleDelFile}
            color="primary"
          >
            <DelIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default FilePicker;

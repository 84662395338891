// Drawer.js
import React from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';

import Divider from '@material-ui/core/Divider';
import UiDrawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';

import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Dot from '@material-ui/icons/AdjustOutlined';

import useStyles from './styles';

const DrawerItems = ({toggleDrawer = () => null, classes: parentClasses}) => {
  const history = useHistory();

  const changePassword = localStorage.getItem('changePassword');
  const userStatus = useSelector(state => state.Login.status);

  const classes = {...parentClasses, ...useStyles()};

  const handleLink = link => {
    history.push(`/dashboard${link}`);
    toggleDrawer('close');
  };

  return (
    <div>
      <div className={classes.toolbar} />
      <Divider />

      {!(userStatus === 9 || changePassword) && (
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Dashboard
            </ListSubheader>
          }
          className={classes.drawerList}
        >
          <ListItem button onClick={() => handleLink('')}>
            <ListItemIcon>
              <Dot />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>

          {userStatus === 4 && (
            <ListItem button onClick={() => handleLink('/historico')}>
              <ListItemIcon>
                <Dot />
              </ListItemIcon>
              <ListItemText primary="Histórico" />
            </ListItem>
          )}
        </List>
      )}

      {(userStatus === 2 || userStatus === 3) && (
        <>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Gestor
              </ListSubheader>
            }
            className={classes.drawerList}
          >
            {userStatus === 2 && (
              <>
                <ListItem button onClick={() => handleLink('/artigos')}>
                  <ListItemIcon>
                    <Dot />
                  </ListItemIcon>
                  <ListItemText primary="Artigos" />
                </ListItem>

                <ListItem button onClick={() => handleLink('/gerentes')}>
                  <ListItemIcon>
                    <Dot />
                  </ListItemIcon>
                  <ListItemText primary="Gerentes" />
                </ListItem>
              </>
            )}

            <ListItem button onClick={() => handleLink('/clientes')}>
              <ListItemIcon>
                <Dot />
              </ListItemIcon>
              <ListItemText primary="Clientes" />
            </ListItem>
          </List>
        </>
      )}
    </div>
  );
};

const Drawer = ({classes, open, toggleDrawer}) => {
  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden smUp implementation="css">
        <UiDrawer
          variant="temporary"
          // anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={open}
          onClose={toggleDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <DrawerItems classes={classes} toggleDrawer={toggleDrawer} />
        </UiDrawer>
      </Hidden>

      <Hidden xsDown implementation="css">
        <UiDrawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <DrawerItems classes={classes} toggleDrawer={toggleDrawer} />
        </UiDrawer>
      </Hidden>
    </nav>
  );
};

export default Drawer;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useRef, useState, useMemo} from 'react';
import clsx from 'clsx';

import {makeStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DelIcon from '@material-ui/icons/HighlightOffTwoTone';

import baseURL from '../../configs/baseURL';

import camera from '../../assets/camera.svg';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },

  label: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 100,
    minWidth: 100,
    marginTop: 10,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#ddd',
    borderStyle: 'dashed',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
  },

  noBorder: {
    borderWidth: 0,
  },

  none: {
    display: 'none',
  },

  none2: {
    display: 'none',
  },

  delBtn: {
    position: 'absolute',
    marginLeft: 150,
    marginBottom: 140,
  },
});

const ImgPicker = ({
  height = 100,
  width = 100,
  type = null,
  image = null,
  disabled = false,
  className = null,
  onChange = () => null,
}) => {
  const input = useRef();
  const classes = useStyles();

  const [thumbnail, setThumbnail] = useState(null);

  const preview = useMemo(() => {
    return thumbnail
      ? URL.createObjectURL(thumbnail)
      : image
      ? `${baseURL.addr}/imgs/${type}/${image}`
      : null;
  }, [type, image, thumbnail]);

  const handleOnChange = ({target}) => {
    const img = target.files[0];
    setThumbnail(img || null);
    onChange(img || null);
  };

  const handleDelFile = () => {
    input.current.value = null;
    setThumbnail(null);
    onChange(null);
  };

  return (
    <div className={className}>
      <div className={classes.root}>
        <label
          className={classes.label}
          style={{backgroundImage: `url(${preview})`, width, height}}
        >
          <input
            type="file"
            ref={input}
            onChange={handleOnChange}
            className={classes.none}
            disabled={disabled}
          />

          <img
            src={camera}
            alt="Select img"
            className={clsx({
              [classes.none]: image,
              [classes.none2]: thumbnail,
            })}
          />
        </label>

        {(thumbnail || image) && !disabled && (
          <IconButton
            className={classes.delBtn}
            onClick={handleDelFile}
            color="primary"
          >
            <DelIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default ImgPicker;

import React from 'react';

import './styles.css';

import contatoWhatsapp from '../../../../../assets/icon-contatos-whatsapp.png';

const WhatsAppBtn = () => {
  return (
    <div className="whatsapp-btn">
      <a href="https://wa.me/5538991285348" target="_blank" rel="noreferrer">
        <div className="whatsapp-btn-content">
          <img src={contatoWhatsapp} alt="Contato" />
          Fale com um especialista
        </div>
      </a>
    </div>
  );
};

export default WhatsAppBtn;

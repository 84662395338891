import React, {useContext, useRef, useState, useMemo} from 'react';
import {Form} from '@unform/web';
import * as Yup from 'yup';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';

import {AlertContext} from '../../../../../App';

import api from '../../../../../services/api';
import whichError from '../../../../../services/whichError';

import countryCodes from '../../../../../tools/countryCodes.json';

import Select from '../../../../../components/Form/Select';
import Input from '../../../../../components/Form/Input';
import Switch from '../../../../../components/Form/Switch';

const Email = ({clientId, onClose = () => null}) => {
  const showAlert = useContext(AlertContext);
  const formRef = useRef(null);

  const [isSaving, setIsSaving] = useState(false);

  const cc = useMemo(
    () =>
      Object.values(countryCodes).map(item => ({
        value: item.ddi,
        label: `${item.pais} (+${item.ddi})`,
      })),
    [],
  );

  const handleSubmit = async (unformData, {reset}) => {
    formRef.current.setErrors({});

    const schema = Yup.object().shape({
      ddi: Yup.number()
        .transform((cv, or) =>
          or === '' || or === '0' ? null : or === '$null$' ? null : cv,
        )
        .nullable(true)
        .typeError('Informe um valor válido')
        .positive('Informe um valor válido')
        .required('Informe o DDI'),
      phone: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .min(5, 'Informe um telefone válido')
        .required('Informe o telefone'),
    });

    try {
      await schema.validate(unformData, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }

      return;
    }

    const data = schema.cast(unformData);
    data.skipPhoneConfirmation = true;
    data.userId = clientId;

    setIsSaving(true);

    const save = await api
      .post(`/core/userphones`, data)
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    setIsSaving(false);

    if (save) {
      reset();
      onClose(true);
    }
  };

  const handleOnClose = () => onClose(false);

  return (
    <Dialog
      open={!!clientId}
      onClose={() => (isSaving ? null : onClose(false))}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Telefone do Cliente</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Informe o telefone que deseja cadastrar.
        </DialogContentText>

        <Form ref={formRef} onSubmit={handleSubmit} initialData={{ddi: 55}}>
          <Select
            label="DDI *"
            name="ddi"
            options={cc}
            variant="outlined"
            disabled={isSaving}
            // className={classes.input}
          />

          <Input
            label="Telefone (com DDD) *"
            name="phone"
            fullWidth
            disabled={isSaving}
            variant="outlined"
          />

          <Switch label="Principal" name="isDefault" />
        </Form>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleOnClose}
          variant="contained"
          color="primary"
          disabled={isSaving}
        >
          Cancelar
        </Button>

        <Button
          onClick={() => formRef.current.submitForm()}
          variant="contained"
          color="primary"
          disabled={isSaving}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Email;

const whichError = (error, sendAdmin) => {
  console.log(
    '------------------------------------\n\n',
    'WhichError  \n\n',
    error,
    '\n\n--------------\n\n',
    'WhichError (stringified)\n\n',
    JSON.parse(JSON.stringify(error)),
    '\n\n',
  );

  if (sendAdmin) {
    // Enviar erro pro admin
  }

  const eReturn = {
    errorMsg: 'Erro na conexão com o servidor!',
    data: error,
    status: null,
  };

  if (error.response) {
    const {status, data} = error.response;
    eReturn.status = status;
    // eReturn.errorMsg = data.errorMsg || data;
    eReturn.errorMsg = data.errorMsg
      ? data.errorMsg
      : data === 'Unauthorized'
      ? 'Não autorizado'
      : data;
  }

  console.log(
    '\n\nWhichError (return)\n\n',
    eReturn,
    '\n\n------------------------------------',
  );

  return eReturn;
};

export default whichError;

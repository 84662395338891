import React from 'react';
import {Link} from 'react-router-dom';
import {scroller} from 'react-scroll';
import Menu, {Item as MenuItem, Divider} from 'rc-menu';

const NavMenu = () => {
  const appToken = localStorage.getItem('apptoken');

  const onClick = ({item}) => {
    if (item.props.link === 'client') {
      return;
    }

    scroller.scrollTo(item.props.link, {smooth: true});
  };

  return (
    <Menu onClick={onClick} selectable={false}>
      <MenuItem key="1" link="client" className="nav-menu-item">
        <Link to="/#sobre">Sobre</Link>
      </MenuItem>

      <Divider />

      <MenuItem key="2" link="client" className="nav-menu-item">
        <Link to="/artigos">Artigos</Link>
      </MenuItem>

      <Divider />

      <MenuItem key="3" link="client" className="nav-menu-item">
        <Link to="/#servicos">Serviços</Link>
      </MenuItem>

      <Divider />

      <MenuItem key="4" link="client" className="nav-menu-item">
        <Link to="/#contato">Contato</Link>
      </MenuItem>

      <Divider />

      <MenuItem key="5" link="client" className="nav-menu-item">
        <Link to="/#mapa">Localização</Link>
      </MenuItem>

      <Divider />

      <MenuItem key="6" link="client" className="nav-menu-item">
        {appToken ? (
          <Link to="/logout">Desconectar</Link>
        ) : (
          <Link to="/login">Área do Cliente</Link>
        )}
      </MenuItem>
    </Menu>
  );
};

export default NavMenu;

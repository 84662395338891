import React, {useContext, useRef, useState} from 'react';
import {Form} from '@unform/web';
import * as Yup from 'yup';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {makeStyles} from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import AddIcon from '@material-ui/icons/Add';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Input from '../../../../components/Form/Input';
import ImgPicker from '../../../../components/ImgPicker';

import Header from '../../components/Header';

Numeral.locale('pt-br');

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 550,
    padding: theme.spacing(2),
  },

  textInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const ManagerAdd = ({history}) => {
  const showAlert = useContext(AlertContext);

  const classes = useStyles();
  const formRef = useRef(null);

  const [img, setImg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (unformData, {reset}) => {
    formRef.current.setErrors({});

    const schema = Yup.object().shape({
      name: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .min(1, 'O nome deve ter no mínimo 1 caractere.')
        .required('Favor informar o nome do gerente'),
      email: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .email('Favor inserir um e-mail válido')
        .required('Favor informar o nome do gerente'),
    });

    try {
      await schema.validate(unformData, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }

      return;
    }

    const data = schema.cast(unformData);
    // console.log('unformData :>> ', unformData);
    // return;

    setIsLoading(true);

    data.manager = true;
    data.skipEmailConfirmation = true;
    data.skipPhoneConfirmation = true;

    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.append('formData', JSON.stringify(data));

    if (img) formData.append('img', img);

    const save = await api
      .post(`/core/users`, formData)
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    setIsLoading(false);

    if (save) {
      reset();
      history.goBack();
    }
  };

  return (
    <>
      <Header title="Adicionar Gerente" />

      <Paper className={classes.root}>
        <Form ref={formRef} onSubmit={handleSubmit}>
          {/* <ImgPicker height={153} width={272} onChange={setImg} /> */}
          <ImgPicker height={150} width={150} onChange={setImg} />

          <Input
            label="Nome *"
            name="name"
            className={classes.textInput}
            variant="outlined"
            fullWidth
          />

          <Input
            label="E-mail *"
            name="email"
            className={classes.textInput}
            variant="outlined"
            fullWidth
          />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            className={classes.button}
            startIcon={<AddIcon />}
            disabled={isLoading}
          >
            Adicionar
          </Button>
        </Form>
      </Paper>
    </>
  );
};

export default ManagerAdd;

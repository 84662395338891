import React, {
  useContext,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import {Form} from '@unform/web';
import * as Yup from 'yup';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import TimelineIcon from '@material-ui/icons/Timeline';
import EditIcon from '@material-ui/icons/EditOutlined';
import SaveIcon from '@material-ui/icons/SaveOutlined';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Input from '../../../../components/Form/Input';
import ImgPicker from '../../../../components/ImgPicker';

import Header from '../../components/Header';

import Emails from './components/Emails';
import Phones from './components/Phones';

import useStyles from './styles';

Numeral.locale('pt-br');

const Client = ({history, match}) => {
  const showAlert = useContext(AlertContext);
  const {params} = match;
  const {userId} = params;

  const classes = useStyles();
  const formRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState(null);

  const [edit, setEdit] = useState(false);
  const [editing, setEditing] = useState(false);

  const [img, setImg] = useState(null);
  const [imgAction, setImgAction] = useState(false);

  const loadData = useCallback(async () => {
    setIsLoading(true);

    await api
      .get(`/core/users/${userId}`)
      .then(async ({data}) => {
        setResult(data);
        setIsLoading(false);
      })
      .catch(async e => {
        showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
        setIsLoading(false);
      });
  }, [userId, showAlert]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSubmit = async unformData => {
    formRef.current.setErrors({});

    const schema = Yup.object().shape({
      name: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .min(3, 'Informe um nome válido')
        .required('Informe o nome'),
    });

    try {
      await schema.validate(unformData, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }

      return;
    }

    const data = schema.cast(unformData);

    const hasImg = !!result.userInformations.img;
    data.imgAction =
      hasImg && imgAction && img === null
        ? 'remove'
        : hasImg && !imgAction && img === null
        ? 'keep'
        : 'update';

    const formData = new FormData();
    formData.append('formData', JSON.stringify(data));

    if (img) formData.append('img', img);

    setEditing(true);

    const save = await api
      .put(`/core/profile/${userId}`, formData)
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    setEdit(false);
    setEditing(false);

    if (save) {
      setImgAction(false);
      loadData();
    }
  };

  const handleImg = image => {
    setImgAction(true);
    setImg(image);
  };

  const handleClientPosts = () =>
    history.push(`/dashboard/cliente/${userId}/postagens`);

  const handleSetEdit = () =>
    setEdit(p => {
      if (p) {
        formRef.current.setFieldValue('name', result.userInformations.name);
        setImgAction(false);
      }

      return !p;
    });

  const handleEdit = editVal => setEdit(editVal);
  const handleEditing = editingVal => setEditing(editingVal);
  const handleLoadData = () => loadData();

  return (
    <>
      <Header
        title="Detalhes do Cliente"
        btnTxt={edit ? 'Cancelar' : 'Editar'}
        btnIcon={edit ? null : <EditIcon />}
        onBtn={handleSetEdit}
      />

      <Paper className={classes.root}>
        {isLoading ? (
          <></>
        ) : (
          result && (
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={{
                name: result.userInformations.name,
              }}
            >
              <ImgPicker
                height={150}
                width={150}
                onChange={handleImg}
                type="profile"
                image={imgAction ? null : result.userInformations.img}
                className={classes.filePicker}
                disabled={!edit}
              />

              <Input
                label="Nome *"
                name="name"
                className={classes.textInput}
                variant="outlined"
                fullWidth
                readOnly={!edit}
              />

              <Emails
                userEmails={result.userEmails}
                {...{userId, edit, editing}}
                {...{handleEdit, handleEditing, handleLoadData}}
              />

              <Phones
                userPhones={result.userPhones}
                {...{userId, edit, editing}}
                {...{handleEdit, handleEditing, handleLoadData}}
              />

              {edit && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    className={classes.button}
                    startIcon={<SaveIcon />}
                  >
                    Salvar alterações
                  </Button>

                  <br />
                  <br />
                </>
              )}

              <hr />
              <br />

              <Button
                variant="contained"
                color="primary"
                type="button"
                size="large"
                className={classes.button}
                startIcon={<TimelineIcon />}
                onClick={handleClientPosts}
              >
                Postagens
              </Button>
            </Form>
          )
        )}
      </Paper>
    </>
  );
};

export default Client;

import React, {useContext, useCallback, useEffect, useState} from 'react';
import {format} from 'date-fns';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

import PersonAddIcon from '@material-ui/icons/PersonAdd';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Header from '../../components/Header';
import Loading from '../../components/Loading';
import Search from '../../components/Search';

Numeral.locale('pt-br');

const columns = [
  {
    id: 'createdAt',
    label: 'Cadastro',
    minWidth: 80,
    width: 150,
    format: 'date',
  },
  {id: 'name', label: 'Nome', minWidth: 150},
  {
    id: 'verified',
    label: '',
    width: 40,
    format: 'bool',
    align: 'right',
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  filter: {
    marginRight: 3,
  },
});

const Clients = ({history}) => {
  const showAlert = useContext(AlertContext);
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState([]);
  const [resultInfo, setResultInfo] = useState({
    filter: '',
    page: 1,
    pages: 1,
    total: 0,
    limit: 10,
    orderBy: 'date',
    sortBy: 'DESC',
  });

  const loadData = useCallback(
    async (
      filter = '',
      page = 1,
      limit = 25,
      orderBy = 'date',
      sortBy = 'DESC',
    ) => {
      setIsLoading(true);

      await api
        .get(
          `/core/users?&c=4&p=${page}&l=${limit}&o=${orderBy}&s=${sortBy}&f=${filter}`,
        )
        .then(async res => {
          if (res.data) {
            const {users, ...info} = res.data;
            setResult(users);
            setResultInfo(info);
            setIsLoading(false);
          }
        })
        .catch(e => {
          showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
          setIsLoading(false);
        });
    },
    [showAlert],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleUser = id => {
    history.push(`/dashboard/cliente/${id}`);
  };

  const handleChangePage = (event, newPage) => {
    loadData(
      resultInfo.filter,
      newPage + 1,
      resultInfo.limit,
      resultInfo.orderBy,
      resultInfo.sortBy,
    );
  };

  const handleChangeRowsPerPage = ({target}) => {
    loadData(
      resultInfo.filter,
      1,
      parseInt(target.value),
      resultInfo.orderBy,
      resultInfo.sortBy,
    );
  };

  return (
    <>
      <Header
        title="Clientes"
        btnTxt="Adicionar cliente"
        btnIcon={<PersonAddIcon />}
        onBtn={() => history.push('/dashboard/cliente/incluir')}
      />

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        className={classes.filters}
      >
        <Search onChange={searchVal => loadData(searchVal)} />
      </Grid>

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      ...(column.width ? {width: column.width} : {}),
                      ...(column.minWidth ? {minWidth: column.minWidth} : {}),
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Loading />
                  </TableCell>
                </TableRow>
              ) : (
                result.map(row => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      onClick={() => handleUser(row.id)}
                    >
                      {columns.map(column => {
                        const {id, align, format: valFormat} = column;

                        const value =
                          column.id === 'name'
                            ? row.userInformations[id]
                            : row[id];

                        return (
                          <TableCell key={`${id}.${valFormat}`} align={align}>
                            {valFormat === 'number' ? (
                              Numeral(value).format('0,0')
                            ) : valFormat === 'price' ? (
                              Numeral(value).format('$0,0')
                            ) : valFormat === 'date' ? (
                              format(new Date(value), 'dd/MM/yyyy HH:mm')
                            ) : valFormat === 'bool' ? (
                              <Checkbox checked={!!value} disabled />
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={resultInfo.total}
          rowsPerPage={resultInfo.limit}
          page={resultInfo.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Exibir:"
        />
      </Paper>
    </>
  );
};

export default Clients;

import React, {useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import * as loginActions from '../../../store/actions/login';

import api from '../../../services/api';

const Session = ({gotSession = () => null}) => {
  const dispatch = useDispatch();
  const Login = useSelector(state => state.Login);

  useEffect(() => {
    const cleanLogin = () => {
      delete api.defaults.headers.common.user;
      delete api.defaults.headers.common.apptoken;
      delete api.defaults.headers.common.stoken;

      localStorage.removeItem('user');
      localStorage.removeItem('apptoken');
      localStorage.removeItem('stoken');

      dispatch(loginActions.resetLogin());
    };

    const verifyToken = async () => {
      const {userId, sToken} = Login;
      const token = localStorage.getItem('apptoken');

      if (!userId || !token || !sToken) {
        cleanLogin();
        return;
      }

      const verify = await api
        .get('/auth/jwt', {headers: {apptoken: token, stoken: sToken}})
        .catch(() => {
          // console.log(err);
          // console.log(err.response);
        });

      if (!verify) {
        cleanLogin();
        return;
      }

      gotSession();

      localStorage.setItem('apptoken', verify.data.token);

      api.defaults.headers.common.apptoken = verify.data.token;
      api.defaults.headers.common.stoken = sToken;
      api.defaults.headers.common.user = userId;
    };

    verifyToken();
  }, [Login, dispatch, gotSession]);

  return <></>;
};

export default Session;

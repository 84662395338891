import React from 'react';
import {Element} from 'react-scroll';

import justice from '../../../../../assets/justice.png';

import './styles.css';

const Servicos = () => {
  return (
    <Element name="servicos" className="servicos">
      <h1 className="section-title">Serviços</h1>

      <div className="servicos-justice">
        <img src={justice} alt="Paulo Magalhães" />
      </div>

      <div className="servicos-content">
        <div className="servicos-card">
          <ul>
            <li>Acompanhamento de Prisões em Flagrante</li>
            <li>Acompanhamento de Inquéritos Policiais</li>
            <li>Defesas Judiciais e Administrativas</li>
            <li>Pedidos de Liberdade</li>
            <li>Execução Penal</li>
            <li>Tribunal do Juri</li>
            <li>Revisão Criminal</li>
            <li>Recursos</li>
            <li>Sustentações Orais</li>
            <li>Advocacia Criminal de Alta Complexidade</li>
          </ul>
        </div>

        {/* <div className="servicos-space" /> */}
      </div>
    </Element>
  );
};

export default Servicos;

import React from 'react';

import Dot from '@material-ui/icons/AdjustOutlined';

import './styles.css';

const Timeline = () => (
  <div className="timeline-bar-container">
    <div className="timeline-bar-content">
      <Dot className="timeline-bar-dot" />

      <div className="timeline-bar-borders">
        <div className="timeline-bar-border">&nbsp;</div>
        <div className="timeline-bar-line">&nbsp;</div>
        <div className="timeline-bar-border">&nbsp;</div>
      </div>
    </div>
  </div>
);

export default Timeline;

import React from 'react';
import {Spinner} from 'react-activity';

const Loading = () => {
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        padding: 20,
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner />
    </div>
  );
};

export default Loading;
